@import '../abstract/variaveis';

.infoscontactos {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: left;
  // justify-content: center;
  h2 {
    font-family: $font;
    font-weight: $sb;
    color: $cor-primaria;
    margin-bottom: 40px;
  }
  span {
    margin-top: 20px;
    font-size: $tamanho-do-texto / 2;
    font-family: $font;
    color: $cor-primaria;
    .ico-contactos {
      font-size: $tamanho-do-texto;
      color: $cor-secundaria;
    }
  }
  .tel-contacto {
    position: relative;
    &::before {
      opacity: 0;
      transition: all 0.3s ease-in-out;
      content: "*Chamada para a rede fixa nacional";
      position: absolute;
      font-size: 10px;
      text-align: center;
      background-color: white;
      color: black;
      border-radius: 8px;
      width: 200px;
      top: 80%;
      left: 5%;
      z-index: 2000;
    }
    &:hover:before {
      opacity: 0.8;
    }
  }
}

@media screen and (max-width: $lg) {
  .infoscontactos {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: left;
    // justify-content: center;
    h2 {
      font-family: $font;
      font-size: $tamanho-do-texto / 2;
      font-weight: $sb;
      color: $cor-primaria;
      margin-bottom: 40px;
    }
    span {
      margin-top: 20px;
      font-size: $tamanho-do-texto / 3;
      font-family: $font;
      color: $cor-primaria;
      .ico-contactos {
        font-size: $tamanho-do-texto / 2;
        color: $cor-secundaria;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .infoscontactos {
    width: 100%;
    h2 {
      margin-bottom: 20px;
    }
    span {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
}