@import '../abstract/variaveis';

.right-card {
  height: 50vh;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  .img-serv-card {
    height: 50vh;
    width: 50%;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    position: relative;
    // &::before {
    //   content: "";
    //   border: 25vh solid transparent;
    //   border-right-color: $cor-secundaria;
    //   right: 0;
    //   opacity: 0.5;
    //   position: absolute;
    //   z-index: 0;
    // }
    .img-services-swipe {
      width: 100%;
      height: 100%;
      .img-swipe {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .serv-txt-container {
    background-color: $cor-cinza;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2% 5%;
    width: 50%;
    z-index: 1;
    h2 {
      margin: 0;
      font-family: $font;
      font-weight: $sb;
      color: $cor-primaria;
    }
    hr {
      width: 200px;
      border: 2px solid $cor-secundaria;
      opacity: 1;
    }
    .p-services {
      padding-right: 5px;
      margin: 10px 0;
      font-family: $font;
      font-weight: $rg;
      color: $cor-primaria;
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        height: 5px !important;
      }
      &::-webkit-scrollbar {
        width: 2px;
        background-color: darkslategrey;
      }
      &::-webkit-scrollbar-track {
        background-color: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        background-color: gray;
      }
      ul {
        margin-top: 1em;
        padding: 0;
        li {
          margin-bottom: 5px;
        }
      }
    }
    div a {
      &:hover .seta {
        animation: $anime-setinha;
      }
    }
  }
}

.left-card {
  height: 50vh;
  display: flex;
  width: 100%;
  .img-serv-card {
    height: 50vh;
    width: 50%;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    position: relative;
    // &::before {
    //   content: "";
    //   left: 0;
    //   border: 25vh solid transparent;
    //   border-left-color: $cor-secundaria;
    //   opacity: 0.5;
    //   position: absolute;
    //   z-index: 0;
    // }
    .img-services-swipe {
      width: 100%;
      height: 100%;
      .img-swipe {
        width: 100%;
        height: 100%;
        object-fit: cover
      }
    }
  }
  .serv-txt-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2% 5%;
    width: 50%;
    z-index: 1;
    h2 {
      margin: 0;
      font-family: $font;
      font-weight: $sb;
      color: $cor-primaria;
    }
    hr {
      width: 200px;
      border: 2px solid $cor-secundaria;
      opacity: 1;
    }
    .p-services {
      padding-right: 5px;
      margin: 10px 0;
      font-family: $font;
      font-weight: $rg;
      color: $cor-primaria;
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        height: 5px !important;
      }
      &::-webkit-scrollbar {
        width: 2px;
        background-color: white;
      }
      &::-webkit-scrollbar-track {
        background-color: $cor-cinza;
      }
      &::-webkit-scrollbar-thumb {
        background-color: gray;
      }
      ul {
        margin-top: 1em;
        padding: 0;
        li {
          margin-bottom: 5px;
        }
      }
    }
    div a {
      &:hover .seta {
        animation: $anime-setinha;
      }
    }
  }
}

@media screen and (max-width: $lg) {
  .right-card {
    height: 40vh;
    .img-serv-card {
      height: 40vh;
      &::before {
        border: 20vh solid transparent;
        content: "";
        right: 0;
        border-right-color: $cor-secundaria;
        opacity: 0.2;
        position: absolute;
      }
    }
    .serv-txt-container {
      padding: 2%;
      h2 {
        font-size: $tamanho-do-texto / 3;
      }
      hr {
        width: 100px;
        border: 1.8px solid $cor-secundaria;
      }
      .p-services {
        font-size: $tamanho-do-texto / 4;
        // text-align: justify;
        overflow-x: hidden;
        ul {
          text-align: left;
        }
      }
    }
  }
  
  .left-card {
    height: 40vh;
    .img-serv-card {
      height: 40vh;
      &::before {
        border: 20vh solid transparent;
        content: "";
        left: 0;
        border-left-color: $cor-secundaria;
        opacity: 0.2;
        position: absolute;
      }
    }
    .serv-txt-container {
      h2 {
        font-size: $tamanho-do-texto / 3;
      }
      hr {
        width: 100px;
        border: 1.8px solid $cor-secundaria;
      }
      .p-services {
        font-size: $tamanho-do-texto / 4;
        // text-align: justify;
        overflow-x: hidden;
        ul {
          text-align: left;
        }
      }
    }
  }
}

// @media screen and (max-width: $sm) {
//   .left-card {
//     .img-serv-card {
//       height: 50vh;
//       width: 50%;
//       background-position: center !important;
//       background-size: cover !important;
//       background-repeat: no-repeat !important;
//       position: relative;
//       &::before {
//         content: "";
//         left: 0;
//         border: 25vh solid transparent;
//         border-left-color: $cor-secundaria;
//         opacity: 0.5;
//         position: absolute;
//       }
//     }
// }