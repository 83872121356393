$font: 'Inter', sans-serif;

$lg: 300;
$rg: 400;
$md: 500;
$sb: 600;
$b: 700;
$xb: 800;
$xxb: 900;

// Cores
$cor-primaria: #19054A;
$cor-secundaria: #C1AD00;
$cor-terc-blue: #053774;
$cor-terc-red: #900009;
$cor-terc-green: #005119;
$cor-branca: #fff;
$cor-cinza: rgba(217, 217, 217,40%);

// Tamanho Fontes
$tamanho-do-texto: 3rem;

// Media Screen
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

// Margins
$maior-lg: 10vw;
$menor-lg: 2vw;

@keyframes setinha {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(5px);
  }
}

$anime-setinha: setinha 0.5s infinite alternate ease-in-out;