@import '../abstract/variaveis';

.contactos {
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 500px;
  gap: 20px;
  width: 100%;
  max-width: 100vw !important;
  padding: 50px 10%;
}

@media screen and (max-width: $sm) {
  .contactos {
    flex-direction: column;
    padding: 50px 5%;
  }
}