@import '../abstract/variaveis';

.ourhistory {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 0 80%;
  padding: 40px 0;
  margin: 0 10%;
  min-height: 400px;
  position: relative;
  h2 {
    font-family: $font;
    font-weight: $sb;
    color: $cor-primaria;
    text-align: center;
  }
  hr {
    width: 200px;
    border: 2px solid $cor-secundaria;
    opacity: 1;
    margin: 5px 0;
  }
  p{
    font-family: $font;
    font-weight: $rg;
    color: $cor-primaria;
    text-align: center;
    width: 60%;
  }
  .card-aboutUs {
    position: absolute;
    top: 50%;
    display: flex;
    height: 400px;
    background-color: $cor-secundaria;
    padding: 8px;
    .img-van {
      // height: 100%;
      width: 50%;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
    }
    .txt-aboutUs-container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-left: 8px;
      width: 50%;
      h1 {
        font-family: $font;
        font-weight: $sb;
        font-size: $tamanho-do-texto / 2;
        color: $cor-primaria;
        text-align: left;
        // margin: 0 10%;
      }
      hr {
        width: 100px;
        border: 2px solid $cor-terc-red;
        margin: 5px 0;
        // margin-left: 10%;
      }
      p {
        font-family: $font;
        font-weight: $rg;
        color: $cor-primaria;
        font-size: $tamanho-do-texto / 3;
        text-align: left;
        // margin: 0 10%;
        width: 100%;
      }
      .txt-mobile { display: none; }
      .txt-tablet { display: none; }
    }
  }
}

@media screen and (max-width: $xl) {
  .ourhistory {
    .card-aboutUs {
      .txt-aboutUs-container {
        .txt-desktop { display: none; }
        .txt-tablet { display: block; }
      }
    }
  }
}

@media screen and (max-width: $md) {
  .ourhistory {
    width: 0 90%;
    margin: 0 5%;
    p{
      font-size: $tamanho-do-texto / 3.5;
      width: 100%;
    }
    .card-aboutUs {
      flex-direction: column;
      .img-van {
        height: 200px !important;
        width: 100%;
      }
      .txt-aboutUs-container {
        height: 50% !important;
        width: 100%;
        margin: 5px 0;
        h1 {
          font-size: $tamanho-do-texto / 2.5;
          margin: 5px 0;
        }
        hr {
          width: 100px;
          border: 1.5px solid $cor-terc-red;
          margin: 0 0 5px 0;
        }
        p {
          font-size: $tamanho-do-texto / 3.5;
          margin: 0;
          width: 100%;
        }
        .txt-desktop { display: none; }
        .txt-tablet { display: none; }
        .txt-mobile { display: block; }
      }
    }
  }
}