@import '../abstract/variaveis';

.my-topBar {
  background-color: $cor-primaria;
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 5px 10vw;
  position: relative;
  z-index: 1 !important;
  div {
    display: flex;
    align-items: center;
    font-family: $font;
    font-size: $tamanho-do-texto / 3;
    gap: 5px;
    color: white;
    p {
      // height: 100%;
      text-align: center;
      margin: 0;
    }
  }
  .telefone {
    position: relative;
    &::before {
      opacity: 0;
      transition: all 0.3s ease-in-out;
      content: "*Chamada para a rede fixa nacional";
      position: absolute;
      font-size: 10px;
      text-align: center;
      background-color: white;
      color: black;
      border-radius: 8px;
      width: 200px;
      top: 115%;
      z-index: 2000;
    }
    &:hover:before {
      opacity: 0.8;
    }
  }
}

@media screen and (max-width: $lg) {
  .my-topBar {
    padding: 0;
    div {
      display: none;
    }
  }
}