@import '../abstract/variaveis';

.header {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100%;
  min-height: 500px !important;
  overflow-x: hidden;
  position: relative;
  .video {
    overflow: hidden;
    position: absolute;
    height: 100% !important;
    width: 100% !important;
    top: 0;
    z-index: 0;
    video {
      position: absolute;
      z-index: 0;
      object-fit: cover;
      width:100%;
      height:100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &::-webkit-media-controls {
        display:none !important;
      }
    }
  }
  .txt-container-header {
    position: absolute;
    height: calc(100% - (100px + 30px + 20px));
    top: (100px + 30px + 20px);
    display: flex;
    flex-direction: column;
    overflow: hidden !important;
    justify-content: center;
    width: 80%;
    margin: 0 10%;
    z-index: 0;
    .c-p { color: $cor-primaria };
    .c-s { color: $cor-secundaria };
    .c-tb { color: $cor-terc-blue };
    .c-tr { color: $cor-terc-red };
    .c-tg { color: $cor-terc-green };
    .c-b { color: $cor-branca };
    .header-title {
      // color: $cor-branca;
      font-family: $font;
      font-weight: $b;
      font-size: $tamanho-do-texto + 2;
      margin: 0;
      animation: animation-slide-in-up 2s forwards;
      transition: transform 0.3s ease;
    }
    .header-subtitle {
      // color: $cor-branca;
      font-family: $font;
      font-size: ($tamanho-do-texto + 2) / 3;
      margin: 0;
      animation: animation-slide-in-up 2s forwards;
      transition: transform 0.3s ease;
    }
  }
}

@media screen and (max-width: $xl) {
  .header {
    .txt-container-header {
      height: calc(100% - (100px + 30px - 16px));
      top: (100px + 30px - 16px);
      .header-title {
        font-size: $tamanho-do-texto + 1;
      }
      .header-subtitle {
        font-size: ($tamanho-do-texto + 1) / 3;
      }
    }
  }
}

@media screen and (max-width: $lg) {
  .header {
    .txt-container-header {
      .header-title {
        font-size: $tamanho-do-texto;
      }
      .header-subtitle {
        font-size: ($tamanho-do-texto) / 3;
      }
    }
  }
}

@media screen and (max-width: $md) {
  .header {
    // .video { display: none; }
    background-position: center !important;
    min-height: 400px !important;
    .txt-container-header {
      .header-title {
        font-size: $tamanho-do-texto - 1;
      }
      .header-subtitle {
        font-size: ($tamanho-do-texto - 1) / 3;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .header {
    .txt-container-header {
      height: calc(100% - (100px + 30px - 32px));
      top: (100px + 30px - 32px);
    }
  }
}

@keyframes animation-slide-in-up {
  from {
    opacity: 0;
    transform: translateY(40%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}